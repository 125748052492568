import axios, { AxiosInstance } from "axios";
// import { EnumGrantType } from "./requestTypes";
import { useRecoilState } from "recoil";
import { userAtom } from "../recoil/atoms/atoms";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import fs from 'fs';
import dotenv from 'dotenv';

// 创建 axios 实例
const axiosInstance: AxiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL, // 设置基础 URL
    timeout: 10000, // 设置请求超时时间 (10秒)
    // headers: {
    //   'Content-Type': 'application/json',
    //   // 添加其他通用的请求头
    // },
});


// export const login = async (username:string, password:string) => {
//     const response = await axiosInstance.post('/auth/login', {grantType: EnumGrantType.PASSWORD_GRANT, username, password});
//     return response.data;
// }

// export const logout = async () => {
//     const [cookies, removeCookie] = useCookies(['refreshToken','accessToken']);
//     const [loginStatus, setLoginStatus] = useRecoilState(userAtom);
//     const navigate = useNavigate();
//     setLoginStatus({
//         userId: '',
//         loggedIn: false,
//         userName: '',
//         role: '',
//         avatarUrl: '',
//     });
//     removeCookie('refreshToken', {path: '/'});
//     removeCookie('accessToken', {path: '/'});
//     navigate('/');
// }

// export const register = async (username:string, password:string) => {
//     const response = await axiosInstance.post('/api/register', {username, password});
//     return response.data;
// }

// export const getUserInfo = async (refreshToken: string) => {
//     try {
//         const response = await axiosInstance.post('/auth/login', {grantType: EnumGrantType.REFRESH_TOKEN, refreshToken});
//         return response.data;
//     } catch (error) {
//         console.error('Error fetching user info:', error);
//         throw error;
//     }
// }

// export const validateLogginStatus = () => {
//     const [loginStatus] = useRecoilState(userAtom);
//     if(!loginStatus.loggedIn) {
//         throw new Error('login required');
//     }
// }

export const loadEnv = () => {
    dotenv.config({ path: '.env.development.local' });
    dotenv.config({ path: '.env.development' });
    dotenv.config({ path: '.env.production.local' });
    dotenv.config({ path: '.env.production' });
    dotenv.config({ path: '.env' });
}

export const adjustTimeFormat = (timestamp: Date) => {
    const now = new Date();
    const seconds = Math.floor((now.getTime() - timestamp.getTime()) / 1000);
    const isSameDay = now.toDateString() === timestamp.toDateString();

    // 判断是否是昨天
    const yesterday = new Date(now);
    yesterday.setDate(now.getDate() - 1);
    const isYesterday = yesterday.toDateString() === timestamp.toDateString();

    // 判断是否是前天
    const twoDaysAgo = new Date(now);
    twoDaysAgo.setDate(now.getDate() - 2);
    const isTwoDaysAgo = twoDaysAgo.toDateString() === timestamp.toDateString();

    // 判断是否是一周以内
    const oneWeekAgo = new Date(now);
    oneWeekAgo.setDate(now.getDate() - 7);
    const isWithinWeek = timestamp > oneWeekAgo;

    if (seconds < 60) {
        return '刚刚';
    } else if (seconds < 3600) {
        return Math.floor(seconds / 60) + '分钟前';
    } else if (isSameDay) {
        return timestamp.toLocaleTimeString('zh-CN', { hour: '2-digit', minute: '2-digit' });
    } else if (isYesterday) {
        return `昨天 ${timestamp.toLocaleTimeString('zh-CN', { hour: '2-digit', minute: '2-digit' })}`;
    } else if (isTwoDaysAgo) {
        return `前天 ${timestamp.toLocaleTimeString('zh-CN', { hour: '2-digit', minute: '2-digit' })}`;
    } else if (isWithinWeek) {
        const daysAgo = Math.floor((now.getTime() - timestamp.getTime()) / (1000 * 60 * 60 * 24));
        return `${daysAgo}天前 ${timestamp.toLocaleTimeString('zh-CN', { hour: '2-digit', minute: '2-digit' })}`;
    } else if (now.getFullYear() === timestamp.getFullYear()) {
        return timestamp.toLocaleString('zh-CN', { month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' });
    } else {
        return timestamp.toLocaleString('zh-CN', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' });
    }
};
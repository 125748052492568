import React, { useEffect, useState, useRef } from 'react';
import RightSideBar from '../components/UserHomePage/RightSideBar';
import PrimaryBlock from '../components/UserHomePage/PrimaryBlock';
import SecondaryBlock from '../components/UserHomePage/SecondaryBlock';
import { Routes, Route, useNavigate, useLocation, Navigate } from 'react-router-dom';
import PostsBlock from '../components/UserHomePage/PostsBlock';
import iconCamera from '../../public/assets/icons/icon-camera-ffffff.svg'
import SettingsBlock from '../components/UserHomePage/SettingsBlock';
import ProfileBlock from '../components/UserHomePage/ProfileBlock';
import { graphql } from '../gql';
import { useMutation, useLazyQuery } from '@apollo/client';  
import BlackListBlock from '../components/UserHomePage/BlackListBlock';
import { userAtom } from "../recoil/atoms/atoms"
import { useRecoilState } from 'recoil';
import { Oval } from 'react-loader-spinner';

const BlockButton: React.FC<{
  text: string;
  tag: string;
  onClick: () => void;
}> = ({ text, tag, onClick }) => {
  const currentPath = useLocation();
  const [isFocused, setIsFocused] = useState(false);

  useEffect(() => {
    const path = currentPath.pathname.split('/');
    if (path[path.length - 1] === tag) {
      setIsFocused(true);
    } else {
      setIsFocused(false);
    }
  }, [currentPath]);

  return (
    <button onClick={onClick} className={`border-t py-2 min-w-12 ${isFocused ? ' border-black' : 'border-transparent'}`}>
      {text}
    </button>
  );
};

const UserHomePageQuery = graphql(`
  query userHomePageQuery {
    rayno {
        user_home_page {
            user_home_page_config {
                edit_profile_button
                edit_avatar_button
                posts_span
                followers_span
                following_span
                ai_span
                mark_span
                settings_span
                blacklist_span
            }
            user {
                user_id
                user_name
                user_avatar_url
                user_signature
                user_last_login
                user_post_count
                user_follower_count
                user_following_count
            }
        }
    }
  }
`);

const UploadUserAvatar = graphql(`
  mutation uploadUserAvatar($file: Upload!) {
    uploadUserAvatar(file: $file)
  }
`);

const UserHomePage: React.FC = () => {
  const navigate = useNavigate();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [isEditingProfile, setIsEditingProfile] = useState(false);
  const [userInfo, setUserInfo] = useRecoilState(userAtom);

  const [queryUserHomePage, { data, loading, error }] = useLazyQuery(UserHomePageQuery,{
    onCompleted: (data) => {
      setUserInfo({
        userId: data.rayno.user_home_page.user.user_id,
        loggedIn: true,
        userName: data.rayno.user_home_page.user.user_name,
        role: 'user',
        avatarUrl: data.rayno.user_home_page.user.user_avatar_url,
      });
    }
  });
  
  const [uploadUserAvatar, { loading: uploadUserAvatarLoading, error: uploadUserAvatarError, data: uploadUserAvatarData }] = useMutation(UploadUserAvatar);

  useEffect(() => {
    queryUserHomePage();
  }, []);

  if (loading) return (
    <div className="flex h-full w-full">
      <div className='min-w-72 h-full flex-grow flex-1'/>
      <div className='flex flex-col items-center justify-center w-auto h-full'>
        {/* <span className='text-center text-xl'>Loading...</span> */}
        <Oval
          color="#ff8a00"
          secondaryColor="#FF8A0080"
          height={48}
          width={48}
        />
      </div>
      <div className='min-w-72 h-full flex-grow flex-1'/>
    </div>
  );
  else if (error || !data) return ( 
    <div className="flex h-full w-full">
      <div className='min-w-72 h-full flex-grow flex-1'/>
      <div className='flex flex-col items-center justify-center w-auto h-full'>
        <span className='text-center text-xl'>Error: {error?.message}</span>
      </div>
      <div className='min-w-72 h-full flex-grow flex-1'/>
    </div>
  );
  const config = data.rayno.user_home_page.user_home_page_config;
  const user = data.rayno.user_home_page.user;
 

  const handleAvatarClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  }

  const handleAvatarChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;

    try {
      // 调用 GraphQL mutation 上传文件
      const response = await uploadUserAvatar({
        variables: {
          file, // 直接传入文件
        },
      });
      setUserInfo({
        ...userInfo,
        avatarUrl: response!.data!.uploadUserAvatar,
      });
      console.log('Upload successful:', response?.data?.uploadUserAvatar);
      // 你可以在这里更新 UI 或存储返回的头像 URL
    } catch (err) {
      console.error('Upload failed:', err);
    }
  }

  return (
    <div className="flex h-full w-full gap-8">
      <div className='min-w-72 h-full flex-grow flex-1'/>

      <main className="min-w-[900px] h-full flex flex-col">
        <div className='w-full mt-16 flex items-start justify-start gap-12'>
          <button type="button" className="relative size-40 flex items-center justify-center" onClick={handleAvatarClick}>
            <input type="file" className="hidden" ref={fileInputRef} accept="image/*" onChange={handleAvatarChange}/>
            <div className='size-40 absolute top-0 left-0 z-10 
              rounded-full bg-black/50 flex flex-col items-center
              justify-center gap-1 transition-opacity duration-300 ease-in-out opacity-0 hover:opacity-100'>
              <img
                src={iconCamera}
                className="size-8"
              />
              <span className='text-white text-sm'>{config?.edit_avatar_button}</span>
            </div>
            {
              userInfo.avatarUrl === "" ? (
                <div
                  className="size-40 z-9 rounded-full bg-gray-200"
                  style={{ width: '40px', height: '40px' }}
                />
              ) : (
                <img
                  src={userInfo.avatarUrl}
                  alt="Avatar"
                  className="size-40 z-9 rounded-full object-contain"
                  style={{ maxWidth: '100%', maxHeight: '100%' }}
                />
              )
            }
          </button>
          <div className='flex flex-col items-start gap-4'>
            <span className='text-3xl'>{user.user_name}</span>
            <span className='text-md text-gray-500'>{user.user_signature}</span>
            <div className='flex items-center gap-4'>
              <span className='text-md text-gray-500 hover:underline underline-offset-4 underline-primary cursor-pointer decoration-gray-100'>{user.user_post_count} 帖子</span>
              <span className='text-md text-gray-500 hover:underline underline-offset-4 cursor-pointer'>{user.user_follower_count} 粉丝</span>
              <span className='text-md text-gray-500 hover:underline underline-offset-4 cursor-pointer'>{user.user_following_count} 关注</span>
            </div>
            <span className='text-sm text-gray-500'>IP 格陵兰 • 最后登录 {user.user_last_login}</span>
          </div>
          <div className='flex-grow'/>
          <button onClick={() => setIsEditingProfile(!isEditingProfile)} className='text-sm text-primary border border-primary hover:bg-primary/20 px-4 py-2 rounded-lg'>{isEditingProfile ? '返回主页' : '编辑资料'}</button>
        </div>

        {isEditingProfile ? (
          <div className='w-full h-auto mt-16'>
            <ProfileBlock />
          </div>
        ) : (
          <div className='w-full h-auto flex flex-col'>
            <div className='flex items-center justify-center w-full border-t mt-16 gap-8 text-sm'>
              <BlockButton text={config.posts_span} tag="user" onClick={() => navigate('/user')} />
              <BlockButton text={config.mark_span} tag="collections" onClick={() => navigate('/user/collections')} />
              <BlockButton text={config.following_span} tag="follows" onClick={() => navigate('/user/follows')} />
              <BlockButton text={config.followers_span} tag="fans" onClick={() => navigate('/user/fans')} />
              <BlockButton text={config.blacklist_span} tag="blacklist" onClick={() => navigate('/user/blacklist')} />
              <BlockButton text={config.ai_span} tag="ai" onClick={() => navigate('/user/ai')} />
              <BlockButton text={config.settings_span} tag="settings" onClick={() => navigate('/user/settings')} />
              
            </div>
            <div className='w-full h-full pt-8 px-8 flex flex-col items-center'>
              <Routes>
                <Route path="/" element={<PostsBlock userId={user.user_id} />} />
                <Route path="/ai/*" element={<PrimaryBlock />} />
                <Route path="/collections/*" element={<SecondaryBlock />} />
                <Route path="/follows/*" element={<SecondaryBlock />} />
                <Route path="/fans/*" element={<SecondaryBlock />} />
                <Route path="/settings/*" element={<SettingsBlock />} />
                <Route path="/blacklist/*" element={<BlackListBlock />} />
                <Route path="*" element={<Navigate to="/user" />} />
              </Routes>
            </div>  
          </div>
        )}
      </main>

      <div className='min-w-72 h-full flex-grow flex-1'>
        <RightSideBar />
      </div>
    </div>
  );
};

export default UserHomePage;

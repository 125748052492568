import { graphql } from '../gql';



export type MsgType = 'text' | 'image' | 'audio' | 'video' | 'file';

export const MSG_SUBSCRIPTION = graphql(`
  subscription MsgSub($userId: String!) {
    newMsg(userId: $userId) {
      content
      sessionId
      createdAt
      msgType
      senderId
    }
  }
`);

export const SEND_MSG_TEST = graphql(`
  mutation sendMsgTest($msg: String!, $sessionId: String!, $senderId: String!, $receiverId: String!) {
    sendMsgTest(msg: $msg, sessionId: $sessionId, senderId: $senderId, receiverId: $receiverId)
  }
`);
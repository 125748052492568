



interface PaginationProps {
    currentPage: number; // 当前页
    totalPages: number; // 总页数
    switchToPage: (page: number) => void; // 更新当前页的回调
}

const Pagination: React.FC<PaginationProps> = (props: PaginationProps) => {

    const {currentPage, totalPages, switchToPage} = props;

    const pages: (number | string)[] = []; // 数字表示页码，字符串表示省略号

    pages.push(1);
    if (currentPage > 2) {
        if (currentPage > 3) {
            pages.push("..."); // 左省略号
        }
        pages.push(currentPage - 2); // 当前页的前两页
    }
    if (currentPage > 1) {
        pages.push(currentPage - 1);
    }
    pages.push(currentPage);

    if (currentPage < totalPages - 1) {
        pages.push(currentPage + 1);
    }
    if (currentPage < totalPages - 2) {
        pages.push(currentPage + 2);
        if (currentPage < totalPages - 3) {
            pages.push("..."); // 右省略号
        }
    }

    if (totalPages > 1) {
        pages.push(totalPages - 1);
    }
    
    return (
        <div className="flex items-center justify-between w-full">
            {props.currentPage > 0 && <button className="bg-primary text-white px-2 py-1 rounded-md"  disabled={props.currentPage === 0} 
                onClick={() => props.switchToPage(props.currentPage - 1)}>
                上一页
            </button>}
            {pages.map((page, index) =>
                typeof page === "string" ? (
                    <span key={index} className="text-gray-500 px-2">
                        ...
                    </span>
                ) : (
                    <button
                        key={index}
                        className={`px-3 py-1 rounded-md ${
                            page === currentPage
                                ? "bg-primary text-white"
                                : "bg-gray-200"
                        }`}
                        onClick={() => switchToPage(page)}
                        disabled={page === currentPage}
                    >
                        {page + 1}
                    </button>
                )
            )}

            {props.currentPage < props.totalPages && <button className="bg-primary text-white px-2 py-1 rounded-md" disabled={props.currentPage === props.totalPages} 
                onClick={() => props.switchToPage(props.currentPage + 1)}>
                下一页
            </button>}
        </div>
    );
}

export default Pagination;

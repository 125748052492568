import React, {useEffect, useState} from 'react';
import Post, {PostProps} from '../components/Post';
import SearchIcon from '../../public/assets/icons/search.svg';
import { graphql } from '../gql';
import { useSubscription } from '@apollo/client';
import { useAuth0 } from '@auth0/auth0-react';
import { useRecoilState } from 'recoil';
import { userAtom } from '../recoil/atoms/atoms';
import { useQuery, useMutation, useLazyQuery } from '@apollo/client';
import InfiniteScroll from 'react-infinite-scroll-component';
import MainPagePost from '../components/MainPage/MainPagePost';
import iconFilter from '../../public/assets/icons/icon-filter-666666.svg';
import iconFilterHover from '../../public/assets/icons/icon-filter-999999.svg';
import iconFilterClicked from '../../public/assets/icons/icon-filter-ff8a00.svg';

// import { PostModel } from '../gql/graphql';

const SearchBar = () => {
  return (
    <div className="flex items-center justify-center w-auto">
      <div className="w-96 h-10 rounded-full bg-gray-100 pr-2 pl-4 py-2 flex items-center justify-between">
        <input placeholder="暂时禁用" disabled className="w-full h-full resize-none bg-transparent hover:outline-none focus:outline-none" />
        <button className="flex items-center justify-center p-1 size-8 rounded-full">  {/* hover:bg-gray-200 */}
          <img src={SearchIcon} alt="search" className="size-4"/>
        </button>
      </div>
    </div>
  );
};

const GET_NEW_POSTS_QUERY = graphql(`
  query GET_NEW_POSTS_QUERY(
    $cursor: String, 
    $sortBy: String, 
    $timeRange: [String!], 
    $region: String, 
    $ageRange: [Int!], 
    $attribute: String
  ) {
    rayno {
      main_page {
        get_new_posts(
          cursor: $cursor, 
          sortBy: $sortBy, 
          timeRange: $timeRange, 
          region: $region, 
          ageRange: $ageRange, 
          attribute: $attribute
        ) {
          id
          updated_at
          post_content
          likes_count
          marks_count
          is_mark
          is_like
          is_shareable
          author {
            user_id
            user_name
            user_avatar_url
          }
        }
      }
    }
  }
`);



const MainPage: React.FC = () => {

  const [isFilterHovered, setIsFilterHovered] = useState(false);
  const [isFilterClicked, setIsFilterClicked] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [postIds, setPostIds] = useState<Set<string>>(new Set()); // 用 Set 检查重复 ID
  const [posts, setPosts] = useState<PostProps[]>([]); // 保存所有帖子
  const [cursor, setCursor] = useState(""); // 分页偏移量

  useEffect(() => {
    fetchMorePosts({
      variables: {
        cursor: "", 
        sortBy: "time",
        timeRange: ["", ""],
        region: "",
        ageRange: [0, 200],
        attribute: "verse",
      },
    });
  }, []);

  const [fetchMorePosts, { loading: loadingMore }] = useLazyQuery(GET_NEW_POSTS_QUERY, {
    onCompleted: (data) => {
      if (data?.rayno?.main_page?.get_new_posts) {
        const newPosts = data.rayno.main_page.get_new_posts.filter((post: any) => {
          // 过滤掉已存在的帖子
          return !postIds.has(post.id);
        });

        const newPostsProps = newPosts.map((post: any) => {
          return {
            id: post.id,
            userName: post.author.user_name,
            userId: post.author.user_id,
            avatarUrl: post.author.user_avatar_url,
            content: post.post_content,
            timestamp: post.updated_at,
            isMarked: post.is_mark || false,
            followers: post.likes_count,
            comments: post.marks_count,
            isFollowed: post.is_like || false,
            isShareable: post.is_shareable || false
          }
        });

        // 更新状态
        setPosts((prevPosts) => [...prevPosts, ...newPostsProps]);
        setPostIds((prevPostIds) => {
          const updatedIds = new Set(prevPostIds);
          newPosts.forEach((post: any) => updatedIds.add(post.id));
          return updatedIds;
        });

        // 更新偏移量
        setCursor(newPosts[newPosts.length - 1].id);
      }
    },
    onError: (error) => {
      console.error("Error fetching more posts:", error);
    },
  });

  


  // const handleGetMorePosts = () => {
  //   if (loadingMore) return; // 防止重复加载
  //   fetchMorePosts({
  //     variables: {
  //       cursor: cursor,
  //       sortBy: "time", // 或 "time"，根据需要修改
  //       timeRange: null, // 如果有时间范围条件，可以传递
  //       region: null, // 根据用户选择传递区域条件
  //       ageRange: null, // 根据用户选择传递年龄范围
  //       attribute: null, // 男同属性
  //     },
  //   });
  // }


  return (
    <div className='flex flex-col h-full w-full'>
      <div className="py-4 pl-8 flex fixed top-0 ml-72 bg-white z-10 w-full">
        <SearchBar />
      </div>
      <div className="py-4 pl-8 flex items-center mt-[72px] fixed gap-2 ml-72 z-10 w-full bg-white">
        <button className="h-10 py-2 px-3 text-center rounded-full bg-white hover:bg-gray-100">
          地域
        </button>
        <button className="h-10 py-2 px-3 text-center rounded-full bg-white hover:bg-gray-100">
          年龄
        </button>
        <button className="h-10 py-2 px-3 text-center rounded-full bg-white hover:bg-gray-100">
          属性
        </button>
        <button className="h-10 py-2 px-3 text-center rounded-full bg-white hover:bg-gray-100">
          时间段
        </button>
        <button className="h-10 py-2 px-3 text-center rounded-full bg-white hover:bg-gray-100">
          排序方式
        </button>
        <button className="ml-2 flex items-center justify-center"
          onMouseEnter={() => setIsFilterHovered(true)}
          onMouseLeave={() => {
            setIsFilterHovered(false);
          }}
          onClick={() => setIsFilterClicked(!isFilterClicked)}
        >
          <img src={isFilterClicked ? iconFilterClicked : isFilterHovered ? iconFilterHover : iconFilter} alt="filter" className="size-6 rounded-full"/>
        </button>
      </div>

      <div className="w-full h-auto flex mt-36">
        <div id='left-side-bar' className='min-w-72 h-full'/>
    
        <main className='w-[660px] min-w-[660px] flex flex-col items-center gap-6 ml-48 mr-12 mb-96'>
            {posts.map((item, index) => (
              <MainPagePost 
                key={index}
                userName={item.userName}
                userId={item.userId} 
                avatarUrl={item.avatarUrl}
                content={item.content}
                timestamp={new Date(item.timestamp)}
                isMarked={false}
                followers={item.followers}
                comments={item.comments}
                isFollowed={item.isFollowed}
              />
            ))}
          {/* <InfiniteScroll
            dataLength={posts.length}
            next={handleGetMorePosts}
            hasMore={hasMore}
            loader={<span className='mt-12 text-gray-400'>加载中...</span>}
            endMessage={<span className='mt-12 text-gray-400'>暂无更多内容</span>}
            className='w-full flex flex-col items-center gap-2'
          >
            
          </InfiniteScroll> */}
        </main>

        <div id='right-side-bar' className='min-w-72 h-full flex-grow flex-2'>
          <div className="flex flex-col h-auto">
            <div className='flex flex-col items-start w-auto h-autobg-gray-400'>
              <span className='text-2xl font-bold'>为你推荐</span>
            </div>

            <div className='flex items-center justify-between w-48 bg-gray-200 gap-4'>
              <div className='flex flex-col items-center gap-2 ml-4 my-4'>
                <span className=''>新增关注</span>
                <span>102</span>
              </div>
              <div className='flex flex-col items-center gap-2 mr-4 my-4'>
                <span>新增浏览</span>
                <span>102</span>
              </div>
            </div>

            <div className='w-full flex flex-col items-start mt-2 gap-2'>
              <span className='text-xs text-gray-300'><button onClick={() => {window.open('https://xoleyo.com')}} className='hover:underline'>关于</button>·用户协议·隐私</span>
              <span className='text-xs text-gray-300'>© 2024 Rayno from Xoleyo</span>
            </div>
          </div>

        </div>
      </div>
    </div>
  );
};

export default MainPage;
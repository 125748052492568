import { adjustTimeFormat } from "../../utils/util";
import { Oval } from "react-loader-spinner";
import iconMsgFailed from '../../../public/assets/icons/icon-alert-ea3323.svg';

export interface MsgItemProps {
    id: string;
    created_at: Date;
    previous_msg_time?: Date | null;
    content: string;
    sender_id: string;
    msg_type: string;
    layout: 'left' | 'right' | 'center' | undefined;
    sender_avatar_url: string;
    status: 'sending' | 'sent' | 'failed' | undefined;
}

const isWithinThreeMinutes = (currentTime: Date, previousTime?: Date): boolean => {
    if (!previousTime) {
      // 如果没有上一条消息，则直接返回 false
      return false;
    }
  
    // 计算时间差（单位为毫秒），并转换为分钟
    const timeDifferenceInMinutes = Math.abs(currentTime.getTime() - previousTime.getTime()) / (1000 * 60);
  
    // 检查时间差是否小于或等于 3 分钟
    return timeDifferenceInMinutes <= 3;
}

const MsgItem = (props: MsgItemProps) => {

    const msgTime = adjustTimeFormat(props.created_at);

    const showTime: boolean = props.previous_msg_time ? !isWithinThreeMinutes(props.created_at, props.previous_msg_time) : true;

    if (props.sender_id === 'system') {
        return (
        <div className="w-full flex flex-col items-center gap-4">
            <span className="text-sm text-gray-500">{msgTime}</span>
            <span className="text-sm text-gray-500 bg-gray-200 rounded-lg p-2">{props.content}</span>
        </div>
        );

    } else if (props.layout === 'left') {

        return (
            <div className="w-full items-center flex flex-col gap-4">
                {showTime && <span className="text-sm text-gray-500">{msgTime}</span>}
                <div className="w-full flex flex-col items-center">
                    <div className="w-full flex px-4 items-start gap-3">
                        {props.sender_avatar_url === '' ?
                            <div className="size-10 rounded-full bg-gray-200" /> :
                            <img src={props.sender_avatar_url} alt="avatar" className="size-10 rounded-full" />
                            }
                        { props.msg_type === 'text' ?
                            <span className="bg-gray-200 text-black rounded-[18px] rounded-tl-none py-2 px-3 max-w-96">{props.content}</span> :
                            <img src={props.content} alt="image" className="p-2 rounded-md max-w-[40%] h-auto" />
                            }
                        {props.status === 'sending' && <div className="mt-2 flex items-center justify-center"><Oval
                            color="#ff8a00"
                            secondaryColor="#FF8A0080"
                            height={26}
                            width={26}
                            /></div>}
                        {props.status === 'failed' && <div className="mt-2 flex items-center justify-center"><img src={iconMsgFailed} alt="failed" className="size-4" /></div>}
                    </div>
                </div>
            </div>
        );

    } else if (props.layout === 'right') {
        return (
            <div className="w-full items-center flex flex-col gap-4">
                {showTime && <span className="text-sm text-gray-500">{msgTime}</span>}
                <div className="w-full flex flex-row-reverse px-4 items-start gap-3">
                    {props.sender_avatar_url === '' ?
                        <div className="size-10 rounded-full bg-gray-200" /> :
                        <img src={props.sender_avatar_url} alt="avatar" className="size-10 rounded-full" />
                        }
                    { props.msg_type === 'text' ?
                        <span className="bg-primary text-white rounded-[18px] rounded-tr-none max-w-96 py-2 px-3">{props.content}</span> :
                        <img src={props.content} alt="image" className="rounded-md max-w-[40%] h-auto " />
                        }
                    {props.status === 'sending' && <div className="mt-2 flex items-center justify-center"><Oval
                        color="#ff8a00"
                        secondaryColor="#FF8A0080"
                        height={26}
                        width={26}
                        /></div>}
                    {props.status === 'failed' && <div className="mt-2 flex items-center justify-center">
                        <img src={iconMsgFailed} alt="failed" className="size-6" /></div>}
                </div>
            </div>
        );
    }
}

export default MsgItem;

import React from 'react';

const ProfileBlock: React.FC = () => {
  return (
    <div className='w-full h-full flex flex-col gap-2 items-start'>
        <span className='font-bold'>基础</span>
        <hr className='w-full border-gray-200'/>
    </div>
  );
};

export default ProfileBlock;
import React, {useState, useCallback} from 'react';
import iconFollow from '../../../public/assets/icons/icon-follow-666666.svg';
import iconFollowHover from '../../../public/assets/icons/icon-follow-999999.svg';
import iconFollowClicked from '../../../public/assets/icons/icon-follow-ea3323.svg';
import iconShare from '../../../public/assets/icons/icon-share-666666.svg';
import iconShareHover from '../../../public/assets/icons/icon-share-999999.svg';
import iconMsg from '../../../public/assets/icons/icon-comment-666666.svg';
import iconMsgHover from '../../../public/assets/icons/icon-comment-999999.svg';
import iconMark from '../../../public/assets/icons/icon-mark-666666.svg';
import iconMarkHover from '../../../public/assets/icons/icon-mark-999999.svg';
import iconMarkClicked from '../../../public/assets/icons/icon-mark-ffc800.svg';
import iconMore from '../../../public/assets/more.svg';
import {useRecoilState} from 'recoil';  
import {commentWindowAtom} from '../../recoil/atoms/atoms';
import { useNavigate } from 'react-router-dom';
import {Slate, Editable} from 'slate-react';
import {createEditor} from 'slate';
import PostComponent from './PostComponent';

export interface MainPagePostProps {
  userName: string;
  userId: string;
  avatarUrl: string;
  content: string; // markdown 格式内容
  followers: number;
  comments: number;
  timestamp: Date;
  isFollowed: boolean;
  isMarked: boolean;
}

const MainPagePost: React.FC<MainPagePostProps> = (props: MainPagePostProps) => {

  const navigate = useNavigate();
  const [commentWindowStatus, setCommentWindowStatus] = useRecoilState(commentWindowAtom);
  const [isFollowHovered, setIsFollowHovered] = useState(false);
  const [isMsgHovered, setIsMsgHovered] = useState(false);
  const [isMarkHovered, setIsMarkHovered] = useState(false);
  const [isShareHovered, setIsShareHovered] = useState(false);
  const [isFollowed, setIsFollowed] = useState(props.isFollowed);
  const [isMarked, setIsMarked] = useState(props.isMarked);

  const [isBouncing, setIsBouncing] = useState(false);

  const postContents = JSON.parse(props.content);

  const editor = createEditor();
  const renderElement = useCallback((props: any) => <PostComponent {...props} />, [])

  const handleMouseLeave = () => {
    setIsBouncing(true); // 触发弹跳动画
    setTimeout(() => setIsBouncing(false), 500); // 一段时间后恢复
  };

  // 跳转到用户主页
  const navigateToUserPage = () => {
    navigate(`/notification`);
  }

  // 改变关注状态

  // 获取评论

  // 添加评论

  // 变更收藏状态

  // 打开更多选项

  // 关注

  // 收藏

  // 调整时间格式
  const adjustTimeFormat = (timestamp: string) => {
    const now = new Date();
    const timestampDate = new Date(timestamp);
  
    const seconds = Math.floor((now.getTime() - timestampDate.getTime()) / 1000);
    const isSameDay = now.toDateString() === timestampDate.toDateString();
  
    const isYesterday = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1).toDateString() === timestampDate.toDateString();
    const isDayBeforeYesterday = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 2).toDateString() === timestampDate.toDateString();
  
    if (seconds < 300) { // 5分钟内
      return '刚刚';
    } else if (isSameDay) {
      // 同一天直接返回小时分钟
      return timestampDate.toLocaleTimeString('zh-CN', { hour: '2-digit', minute: '2-digit' });
    } else if (isYesterday) {
      // 昨天
      return `昨天 ${timestampDate.toLocaleTimeString('zh-CN', { hour: '2-digit', minute: '2-digit' })}`;
    } else if (isDayBeforeYesterday) {
      // 前天
      return `前天 ${timestampDate.toLocaleTimeString('zh-CN', { hour: '2-digit', minute: '2-digit' })}`;
    } else {
      const includeYear = now.getFullYear() !== timestampDate.getFullYear();
      // 非今年加上年份，否则只显示月日+小时分钟
      return timestampDate.toLocaleString('zh-CN', {
        year: includeYear ? 'numeric' : undefined,
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
      });
    }
  };

  return (
    <div className="w-full flex flex-col items-center">
      <header className="flex w-full h-12 gap-1 items-center">
        <button onClick={navigateToUserPage}>
          <img src={props.avatarUrl} alt="avatar"
            onError={(e) => {
              const target = e.target as HTMLImageElement;
              target.onerror = null;
              target.src = 'https://via.placeholder.com/60';
            }} className="size-12 mr-2 rounded-full" />
        </button>
        <div className="flex flex-col items-start grow-0 w-auto">
          <button className="font-bold" onClick={navigateToUserPage}>{props.userName}</button>
          <span className="text-xs text-black/[.4]">{adjustTimeFormat(props.timestamp.toISOString())}</span>
        </div>
        <div className="grow"/>
        <button className="size-8 hover:bg-gray-100 flex items-center justify-center rounded-full">
          <img src={iconMore} alt="more" className="w-8 h-8" />
        </button>
      </header>

      <main className="mt-4 flex flex-col w-full">
        <Slate editor={editor} initialValue={postContents}>
            <Editable readOnly={true} renderElement={renderElement} className="w-full gap-4 flex flex-col text-[15px]" />
        </Slate>
      </main>

      <footer className="flex items-center justify-between gap-3 w-full">
        <button
          onClick={() => setIsFollowed(!isFollowed)}
          onMouseEnter={() => setIsFollowHovered(true)}
          onMouseLeave={() => {
            setIsFollowHovered(false);
            handleMouseLeave(); 
          }}
          className={`flex items-center ${isFollowed ? '已关注' : '关注'} py-2 gap-1`}
        >
          <img src={isFollowed ? iconFollowClicked : isFollowHovered ? iconFollowHover : iconFollow} alt="star" className={`size-6 ${isBouncing ? 'animate-bounceGrow' : ''}`} />
          <span className={`${isFollowHovered ? 'text-[#999999]' : 'text-[#666666]'}`}>{props.followers}</span>
        </button>
        <button
          onMouseEnter={() => setIsMsgHovered(true)}
          onMouseLeave={() => setIsMsgHovered(false)}
          onClick={() => setCommentWindowStatus(true)}
          className="flex items-center gap-1 py-2 ">
          <img src={isMsgHovered ? iconMsgHover : iconMsg} alt="star" className="size-6" />
          <span className={`${isMsgHovered ? 'text-[#999999]' : 'text-[#666666]'}`}>{props.comments}</span>
        </button>
        <button
          onClick={() => setIsMarked(!isMarked)}
          onMouseEnter={() => setIsMarkHovered(true)}
          onMouseLeave={() => setIsMarkHovered(false)}
          className="flex items-center gap-1 py-2">
          <img src={isMarked ? iconMarkClicked : isMarkHovered ? iconMarkHover : iconMark} alt="star" className={`size-6`} />
        </button>
        <div className="grow"/>
        <button
          onMouseEnter={() => setIsShareHovered(true)}
          onMouseLeave={() => setIsShareHovered(false)}
          className="flex items-center gap-1 py-2">
          <img src={isShareHovered ? iconShareHover : iconShare} alt="star" className="size-6" />
          <span className={`${isShareHovered ? 'text-[#999999]' : 'text-[#666666]'}`}>分享链接</span>
        </button>
      </footer>
      <div className="h-6 border-b border-gray-200 w-[90%]"/>
    </div>
  );
};

export default MainPagePost;


const PostComponent = ({ attributes, children, element }: { attributes: any, children: any, element: any }) => {

  const style = { textAlign: element.align }
  switch (element.type) {
    case 'block-quote':
      return (
        <blockquote className="text-lg bg-gray-100 " style={style} {...attributes}>
          {children}
        </blockquote>
      )
    case 'heading-one':
      return (
        <h1 className="text-2xl font-bold" style={style} {...attributes}>
          {children}
        </h1>
      )
    case 'heading-two':
      return (
        <h2 className="text-xl font-bold" style={style} {...attributes}>
          {children}
        </h2>
      )
    case 'divider':
      return (
        <div {...attributes} contentEditable={false} className={`w-full h-auto flex flex-col justify-center items-center py-4 border-2 border-transparent`} 
        style={{
          lineHeight: 0,
        }}>
          <hr className="border-gray-200 w-1/2"/>
          {children}
        </div>
      );
      case 'image':
        return (
          <div {...attributes} contentEditable={false} className={`flex flex-col items-center w-full h-auto`}>
            <img src={element.url} className={`w-auto h-auto max-w-full border-2 border-transparent`} />
            {children}
          </div>
        );
    default:
      return (
        <p style={style} {...attributes}>
          {children}
        </p>
      )
  }
}

export default PostComponent;
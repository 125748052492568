import { useEffect, useState, useRef } from "react";
import { useQuery, useLazyQuery, useMutation } from '@apollo/client';
import { graphql } from '../../gql';
import InfiniteScroll from "react-infinite-scroll-component";
import { useRecoilState } from "recoil";
import { userAtom } from "../../recoil/atoms/atoms";
import MsgItem, { MsgItemProps } from "./MsgItem";
import { UPLOAD_IMAGE } from "../../components/EditorPage/Editor";
import { SEND_MSG_TEST } from "../../subscription/MsgSub";
import { uuidv7 } from 'uuidv7';
import imageIcon from '../../../public/assets/icons/icon-image-000000.svg';
import emojiIcon from '../../../public/assets/icons/icon-emoji-000000.svg';
import moreIcon from '../../../public/assets/icons/icon-more-000000.svg';

const MsgListQuery = graphql(`
  query MsgListQuery($sessionId: String!, $cursor: String!, $limit: Int) {
    rayno {
      msg_page {
        messages(sessionId: $sessionId, cursor: $cursor, limit: $limit) {
          sender_id
          content
          created_at
          msg_type
          id
        }
      }
    }
  }
`)

const SEND_MSG = graphql(`
  mutation SendMsg($sessionId: String!, $content: String!, $senderId: String!, $receiverId: String!, $msgId: String!, $msgType: String!) {
    sendMsg(sessionId: $sessionId, content: $content, senderId: $senderId, receiverId: $receiverId, msgId: $msgId, msgType: $msgType)
  }
`)


export interface ChatPanelProps {
  sessionId: string;
  participant: {
    user_id: string;
    user_name: string;
    user_avatar_url: string;
  }
  msgList: MsgItemProps[];
  setMsgList: (msgs: MsgItemProps[]) => void;
  reachTop: boolean;
  setReachTop: (reachTop: boolean) => void;
}


const ChatPanel = (props: ChatPanelProps) => {

  const [msg, setMsg] = useState('');
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const [uploadImage] = useMutation(UPLOAD_IMAGE);
  const imageInputRef = useRef<HTMLInputElement>(null);
  const [userInfo, setUserInfo] = useRecoilState(userAtom);
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const [isLoading, setIsLoading] = useState(false); // 是否正在加载中
  const [cursor, setCursor] = useState('');

  const loadInitialMessages = async () => {
    await getMessages({
      variables: {
        sessionId: props.sessionId,
        cursor: '',
        limit: 10,
      },
    });
  }

  useEffect(() => {
    if(!props.sessionId) return;
    if(props.msgList.length === 0) {
      setIsLoading(true);
      setCursor('');
      loadInitialMessages();
    } else {
      setCursor(props.msgList[0].created_at.toISOString());
    }
  }, [props.sessionId]);

  const [getMessages, {data, loading, error} ] = useLazyQuery(MsgListQuery, {
    onCompleted: (data) => {
      // console.log("onCompleted", props.sessionId, data.rayno.msg_page.messages.length);
      const msgs = data.rayno.msg_page.messages;
      if (msgs.length === 0) {
        props.setReachTop(true);
        setIsLoading(false);
        return;
      }
      else if(msgs.length < 10) {
        // console.log("setReachTop true", props.sessionId);
        props.setReachTop(true);
      }
      setCursor(msgs[0].created_at);
      // 初始加载消息
      if(props.msgList.length === 0) {
        
        props.setMsgList(msgs.map((msg: any, index: number) => ({
          ...msg,
          layout: msg.sender_id === userInfo.userId ? 'right' : 'left',
          sender_avatar_url: msg.sender_id === userInfo.userId ? userInfo.avatarUrl : props.participant.user_avatar_url,
          status: 'sent',
          previous_msg_time: index === 0 ? null : new Date(msgs[index - 1].created_at),
          msg_type: msg.msg_type,
          created_at: new Date(msg.created_at),
          content: msg.content,
          sender_id: msg.sender_id
        })));
      } else { // 加载更多消息
        props.setMsgList([...msgs.map((msg: any) => ({
          ...msg,
          layout: msg.sender_id === userInfo.userId ? 'right' : 'left',
          sender_avatar_url: msg.sender_id === userInfo.userId ? userInfo.avatarUrl : props.participant.user_avatar_url,
          status: 'sent',
          previous_msg_time: props.msgList[props.msgList.length - 1]?.created_at,
          msg_type: msg.msg_type,
          created_at: new Date(msg.created_at),
          content: msg.content,
          sender_id: msg.sender_id
        })), ...props.msgList]);
      }
      setIsLoading(false);
    }
  });


    // const [ sendMsg ] = useMutation(SEND_MSG);
    const [ sendMsgTest, {loading: sendMsgTestLoading, error: sendMsgTestError} ] = useMutation(SEND_MSG_TEST,{
      onCompleted: (data) => {
        console.log(data.sendMsgTest);
      },
      onError: (error) => {
        console.log(error);
      }
    });

    const [ sendMsg, {loading: sendMsgLoading, error: sendMsgError} ] = useMutation(SEND_MSG,{
      onCompleted: (data) => {
        props.setMsgList(props.msgList.map(msg => msg.id === data.sendMsg ? {...msg, status: 'sent'} : msg));
      },
      onError: (error) => {
        console.log(error);
      }
    });


    useEffect(() => {
      requestAnimationFrame(() => {
        scrollToBottom();
      });
    }, [props.msgList]);

    const scrollToBottom = () => {
      if (messagesEndRef.current) {
        messagesEndRef.current.scrollTop = messagesEndRef.current.scrollHeight;
      }
    };

    const handleScroll = () => {
      if (!messagesEndRef.current) return;
  
      const { scrollTop } = messagesEndRef.current;
      // 当滚动到顶部且满足条件时加载更多消息
      if (scrollTop <= 50 && !props.reachTop && !isLoading) {
        loadMoreMsgs();
      }
    };

    const loadMoreMsgs = () => {
      if (props.reachTop || isLoading) return;
  
      setIsLoading(true); // 标记为加载更多消息
      getMessages({
        variables: {
          sessionId: props.sessionId,
        cursor: cursor,
        limit: 10,
      },
      }); // 调用父组件的加载函数
    };

    const handleImageClick = () => {
      if (imageInputRef.current) {
        imageInputRef.current.click();
      }
    }

    const handleImageChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files?.[0];
      const id = uuidv7();
      var newMessage: MsgItemProps = {
        id: id,
        content: "",
        sender_id: userInfo.userId,
        created_at: new Date(),
        previous_msg_time: null,
        msg_type: "image",
        layout: "right",
        sender_avatar_url: userInfo.avatarUrl,
        status: 'sending'
      }

      props.setMsgList([...props.msgList, newMessage]);
      const {data} = await uploadImage({
        variables: {
          file: file
        }
      });
      if(data?.uploadImage) {
        newMessage.content = data?.uploadImage||'';
        sendMsg({
          variables: {
            sessionId: props.sessionId,
            content: newMessage.content,
            senderId: userInfo.userId,
            receiverId: props.participant.user_id,
            msgId: newMessage.id,
            msgType: "image"
          }
        });
      } else {
        newMessage.status = 'failed';
      }
    }

    const handleSendMsg = () => {
      sendMsg({
        variables: {
          sessionId: props.sessionId,
          content: msg,
          senderId: userInfo.userId,
          receiverId: props.participant.user_id,
          msgId: uuidv7(),
          msgType: "text"
        }
      });
      // sendMsgTest({
      //   variables: {
      //     msg: msg,
      //     sessionId: props.sessionId,
      //     senderId: userInfo.userId,
      //     receiverId: "1"
      //   }
      // });
      props.setMsgList([...props.msgList, {
        id: uuidv7(),
        content: msg,
        sender_id: userInfo.userId,
        created_at: new Date(),
        previous_msg_time: props.msgList[props.msgList.length - 1]?.created_at,
        msg_type: "text",
        layout: "right",
        sender_avatar_url: userInfo.avatarUrl,
        status: 'sending'
      }]);
      setMsg('');
    }

    if (loading) return <div>Loading...</div>;
    else if (error) return <div>Error: {error.message}</div>;

    return (
    <div className="w-full h-full flex flex-col items-center">
        <header className="w-full h-16 flex justify-center items-center">
          <span className="text-xl font-bold">
            {props.participant.user_name}
          </span>
        </header>

        <main className="w-full flex-grow border-y overflow-y-auto" ref={messagesEndRef} onScroll={handleScroll} >
          <div className="w-full h-auto flex flex-col items-center gap-6 justify-stretch my-8">
            <div className="w-full h-auto flex flex-col justify-center items-center gap-4 mb-8">
              <img src={props.participant.user_avatar_url} alt="participant_avatar" className="size-32 rounded-full" />
              <span className="text-xl font-bold">{props.participant.user_name}</span>
              <button className="px-4 py-2 bg-gray-100 text-xs rounded-lg font-bold hover:bg-gray-200">
                查看个人主页
              </button>
              { isLoading && <div className="w-full h-auto flex justify-center items-center">
                <div className="w-10 h-10 bg-gray-100 rounded-full animate-spin"></div>
              </div>}
            </div>
            {props.msgList.map((message) => (
              <MsgItem 
                key={message.id}
                id={message.id}
                status={message.status}
                content={message.content}
                sender_id={message.sender_id}
                created_at={message.created_at}
                msg_type={message.msg_type}
                previous_msg_time={message.previous_msg_time}
                layout={message.sender_id === 'system' ? 'center' : message.sender_id === userInfo.userId ? 'right' : 'left'} 
                sender_avatar_url={message.sender_id === 'system' ? "" : message.sender_id === userInfo.userId ? userInfo.avatarUrl : props.participant.user_avatar_url }
            />
          ))}
          </div>
        </main>

        <footer className="w-full min-h-16 flex items-center px-4 py-2 gap-2">
          <img src={emojiIcon} alt="emoji" className="size-8 hover:opacity-50" />
          <button className="size-8 min-w-8 min-h-8 hover:opacity-50" onClick={handleImageClick}>
            <img src={imageIcon} alt="image" className="size-8 hover:opacity-50" />
            <input type="file" className="hidden" ref={imageInputRef} accept="image/*" onChange={handleImageChange}/>
          </button> 
          <button className="size-8 min-w-8 min-h-8 hover:opacity-50">
            <img src={moreIcon} alt="setting" className="size-8 hover:opacity-50  " />
          </button>
          <div className="flex-grow rounded-full border items-center justify-center flex px-4 py-2">
            <textarea
              ref={textareaRef}
              placeholder="发消息..."
              rows={1}
              style={{maxHeight: '60px', overflowY: 'auto'}}
              value={msg}
              onChange={e => setMsg(e.target.value)}
              onKeyDown={e => {
                if (e.key === 'Enter'&&msg.trim()) {
                  e.preventDefault();
                  handleSendMsg();
                }
              }}
              className="w-full h-auto focus:outline-none resize-none"
            />
          </div>
          <button
            onClick={handleSendMsg}
            className={`w-20 h-10 bg-primary text-white font-bold rounded-full ${
              msg.trim() ? "bg-primary" : "bg-primary/50 cursor-not-allowed"
            }`}
          >
            发送
          </button>
        </footer>
    </div>
  )
}

export default ChatPanel;
import React from 'react';
import { adjustTimeFormat } from '../../utils/util';
import { MsgItemProps } from './MsgItem';

export interface SessionItemProps {
  id: string;
  last_msg_at: Date;
  unread_count: number;
  last_message_content: string;
  last_message_type: string;
  participant: {
    user_id: string;
    user_name: string;
    user_avatar_url: string;
  }
  hasMoreMsg: boolean;
  msgList: MsgItemProps[];
  cursor: string;
  reachTop: boolean;
}

const SessionItem: React.FC<SessionItemProps> = props => {

  if (props.participant.user_avatar_url === '') {
    props.participant.user_avatar_url = 'https://storage.googleapis.com/rayno/0194263e-775d-7af7-bc58-4217b07c1354.png';
  }


  return (
    <div className="w-full flex items-center gap-2 p-4 hover:bg-gray-100">
      <div className="relative flex items-center justify-center h-auto w-auto">
        {props.unread_count > 0 && 
          <span className="absolute size-4 rounded-full text-xs text-white text-center bg-red-500 right-[-1] top-[-1]">{props.unread_count<=9?props.unread_count:"..."}</span>
          }
        <img src={props.participant.user_avatar_url} alt="avatar" className="size-12 rounded-full">
        </img>
      </div>
      <div className="flex flex-col h-full justify-start items-start">
        <span className="text-lg">{props.participant.user_name}</span>
        <span className="text-xs w-32 text-gray-400 truncate">{props.last_message_content}</span>
      </div>
      <div className="flex-grow" />
      <span className="text-xs text-gray-400">{adjustTimeFormat(props.last_msg_at)}</span>
    </div>
  );
};

export default SessionItem;

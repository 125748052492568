import React, { useMemo } from 'react'
import { Transforms, createEditor, Descendant } from 'slate'
import { Slate, Editable, useSlateStatic, useSelected, useFocused, ReactEditor } from 'slate-react'
import { withHistory } from 'slate-history'
import closeIcon from '../../../public/assets/icons/icon-close-40px.svg'

export const ImageComponent = ({ attributes, children, element }: any) => {
    const editor = useSlateStatic()
    const path = ReactEditor.findPath(editor, element)
  
    const selected = useSelected()
    const focused = useFocused()
    return (
      <div {...attributes} className={`flex flex-col items-center w-full h-auto`}>
        {children}
        <div
          contentEditable={false}
          className="relative">
            <img
                src={element.url}
                className={`block max-w-full w-auto h-auto border-2 ${selected && focused ? 'border-primary' : 'border-transparent hover:border-primary/50'}`}
            />
            <button
                onClick={() => Transforms.removeNodes(editor, { at: path })}
                className={`${selected && focused ? 'inline' : 'none'} absolute top-2 right-2 bg-gray-200 bg-opacity-25 hover:bg-opacity-60 rounded-full size-6 flex items-center justify-center`}
            >
                <img src={closeIcon} className="w-4 h-4 "/>
            </button>
        </div>
      </div>
    )
}
import React from 'react';

export interface PostsBlockProps {
    userId: string;
}

// 获取对应用户id所有帖子按照时间排序
const PostsBlock: React.FC<PostsBlockProps> = (props) => {


  return (
    <div className='w-full h-full'>
      
    </div>
  );
};

export default PostsBlock;

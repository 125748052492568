import React, { useCallback, useMemo, useState, useRef } from 'react'
import { Editable, withReact, useSlate, Slate, useSelected, useFocused, ReactEditor } from 'slate-react'
import { Editor, Transforms, createEditor, Descendant, Element as SlateElement, BaseEditor, Path } from 'slate'
import { withHistory, HistoryEditor } from 'slate-history'
import { useMutation, useQuery } from '@apollo/client'
import { graphql } from '../../gql';
import { useNavigate } from 'react-router-dom';
import { Oval } from 'react-loader-spinner';
import Picker from '@emoji-mart/react'
import data from '@emoji-mart/data'

import addImageIcon from '../../../public/assets/icons/icon-addImage-40px.svg'
import boldIcon from '../../../public/assets/icons/icon-bold-40px.svg'
import italicIcon from '../../../public/assets/icons/icon-italic-40px.svg'
import underlineIcon from '../../../public/assets/icons/icon-underline-40px.svg'
import dividerIcon from '../../../public/assets/icons/icon-divider-40px.svg'
import clearIcon from '../../../public/assets/icons/icon-clear-40px.svg'
import addEmojiIcon from '../../../public/assets/icons/icon-addEmoji-40px.svg'
import alignLeftIcon from '../../../public/assets/icons/icon-align-left-40px.svg'
import alignCenterIcon from '../../../public/assets/icons/icon-align-center-40px.svg'
import alignRightIcon from '../../../public/assets/icons/icon-align-right-40px.svg'
import quoteIcon from '../../../public/assets/icons/icon-quote-40px.svg'
import h1Icon from '../../../public/assets/icons/icon-heading1-40px.svg'
import h2Icon from '../../../public/assets/icons/icon-heading2-40px.svg'

import { ImageComponent } from './ImageComponent'

const LIST_TYPES = ['numbered-list', 'bulleted-list']
const TEXT_ALIGN_TYPES = ['left', 'center', 'right', 'justify']

export const UPLOAD_IMAGE = graphql(`
  mutation UploadImage($file: Upload!) {
    uploadImage(file: $file)
  }
`);

export const UPLOAD_POST = graphql(`
  mutation UploadPost($post: String!, $post_id: String, $is_publish: Boolean!) {
    uploadPost(post: $post, post_id: $post_id, is_publish: $is_publish)
  }
`);

export const LOAD_SCRIPT  = graphql(`
  query LoadScript {
    rayno {
      editor_page {
        recent_post_script {
          id
          post_content
        }
      }
    }
  }
`);

type CustomText = {
  text: string;
  bold?: boolean;
  italic?: boolean;
  underline?: boolean;
  align?: 'left' | 'center' | 'right';
}

type ParagraphElement = {
  type: 'paragraph';
  children: CustomText[];
  align?: 'left' | 'center' | 'right';
}

type HeadingOneElement = {
  type: 'heading-one';
  children: CustomText[];
  align?: 'left' | 'center' | 'right';
}

type HeadingTwoElement = {
  type: 'heading-two';
  children: CustomText[];
  align?: 'left' | 'center' | 'right';
}

type BlockQuoteElement = {
  type: 'block-quote';
  children: CustomText[];
}

type DividerElement = {
  type: 'divider';
  children: CustomText[];
}

type ImageElement = {
  type: 'image';
  url: string;
  children: CustomText[];
}

type CustomElement = ParagraphElement | HeadingOneElement | HeadingTwoElement | BlockQuoteElement | DividerElement | ImageElement

declare module 'slate' {
  interface CustomTypes {
    Editor: BaseEditor & ReactEditor & HistoryEditor
    Element: CustomElement
    Text: CustomText
  }
}


const withImages = (editor: Editor) => {
  const { insertData, isVoid } = editor
  editor.isVoid = (element) => {
    return (element.type === 'image' || element.type === 'divider') ? true : isVoid(element)
  }
  return editor
}

const PostEditor = () => {
  const renderElement = useCallback((props: any) => <Element {...props} />, [])
  const renderLeaf = useCallback((props: any) => <Leaf {...props} />, [])
  const editor = useMemo(() => withImages(withHistory(withReact(createEditor()))), [])
  const [dragOver, setDragOver] = useState(false);
  const [uploadImage] = useMutation(UPLOAD_IMAGE);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();
  const [uploadPost, { loading: uploadPostLoading, error: uploadPostError , data: uploadPostData}] = useMutation(UPLOAD_POST);
  const [isPublish, setIsPublish] = useState(false);
  const [isSave, setIsSave] = useState(false);
  const { loading: loadScriptLoading, error: loadScriptError , data: loadScriptData} = useQuery(LOAD_SCRIPT);
  let postId: string | null = null;
  
  if(loadScriptLoading) {
    return <Oval color="#ff8a00" secondaryColor="#FF8A0080" height={48} width={48} />
  }

  var initialValue: Descendant[] = JSON.parse(loadScriptData?.rayno?.editor_page?.recent_post_script?.post_content || '[]')
  if(initialValue.length === 0) {
    initialValue = [{
      type: 'paragraph',
      children: [
        { text: '' }
      ],
    }]
  }

  const handleUploadPost = async (isPublish: boolean) => {
    if(editor.children.length === 1 && (editor.children[0] as CustomElement).type === 'paragraph' && (editor.children[0] as ParagraphElement).children[0].text === '') {
      return;
    }
    if(isPublish) {
      setIsPublish(true)
    }
    else {
      setIsSave(true)
    }
    const post = JSON.stringify(editor.children);
    // console.log('post:', post)
    const result = await uploadPost({
        variables: {
          post: post,
          post_id: postId,
          is_publish: isPublish,
      },
    });
    if(result.data?.uploadPost === 'failed') {
      console.log('上传失败')
      // 上传失败
    }
    else {
      if(isPublish) {
        navigate('/user')
      }
      else {
        // 保存成功弹出提示
        console.log('保存成功')
      }
    }
    setIsPublish(false)
    setIsSave(false)
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {

    if (event.key === 'Enter') {
      const { selection } = editor;
      // 如果没有选区，直接返回
      if (!selection) {
        return;
      }
      // 查找当前选中的节点是否为 divider 或 image
      const [selectedNodeEntry] = Editor.nodes(editor, {
        at: selection,
        match: n =>
          SlateElement.isElement(n) && (n.type === 'divider' || n.type === 'image'),
      });

      // 如果选中的是 divider 或 image 节点
      if (selectedNodeEntry) {
        const [, path] = selectedNodeEntry;

        // 在当前节点后插入一个新的 paragraph 节点
        Transforms.insertNodes(
          editor,
          {
            type: 'paragraph',
            children: [{ text: '' }],
          },
          { at: Path.next(path) } // 在当前节点之后插入
        );

        // 阻止默认行为
        event.preventDefault();
      }
    } 
    else if (event.ctrlKey || event.metaKey) {
      switch (event.key) {
        case 'z': {
          event.preventDefault()
          editor.undo()
          break
        }
        case 'y': {
          event.preventDefault()
          editor.redo()
          break
        }
        case 'b': {
          event.preventDefault()
          toggleMark(editor, 'bold')
          break
        }
        case 'i': {
          event.preventDefault()
          toggleMark(editor, 'italic')
          break
        }
        case 'u': {
          event.preventDefault()
          toggleMark(editor, 'underline')
          break
        }
      }
    }
  }

  const handleDrop = async (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()

    const files = Array.from(event.dataTransfer.files)
    const images = files.filter(file => file.type.startsWith('image/'))
    setDragOver(false)

    images.forEach(async file => {
      const imageUrl = await uploadImage({
        variables: {
          file: file,
        },
      });
      // console.log('imageUrl', imageUrl)
      // console.log('imageUrl.data', imageUrl.data)
      if (imageUrl.data?.uploadImage) {
        let {selection} = editor;
        if(!selection) {
          Transforms.insertNodes(editor, {
            type: 'image',
            url: imageUrl.data.uploadImage,
            children: [{ text: '' }],
          }, { at: Editor.end(editor, []) });
          // 在图片后插入段落
          Transforms.insertNodes(editor, {
            type: 'paragraph',
            children: [{ text: '' }],
          }, { at: Editor.end(editor, []) });
        } else {
          const [selectedNodeEntry] = Editor.nodes(editor, {
            at: selection,
            match: n => SlateElement.isElement(n),
          });
  
          if (selectedNodeEntry) {
            const [, path] = selectedNodeEntry;
    
            // 在当前节点后插入一个新的 paragraph 节点
            Transforms.insertNodes(
              editor,
              {
                type: 'image',
                url: imageUrl.data.uploadImage,
                children: [{ text: '' }],
              },
              { at: Path.next(path) } // 在当前节点之后插入
            );
            Transforms.move(editor);
          }
        }
      }
    });
  };

  const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;

    if (files) {
      Array.from(files).forEach(async file => {
        const imageUrl = await uploadImage({
          variables: {
            file: file,
          },
        });
        // console.log('imageUrl', imageUrl)
        // console.log('imageUrl.data', imageUrl.data)
        if (imageUrl.data?.uploadImage) {
          let {selection} = editor;
          if(!selection) {
            Transforms.insertNodes(editor, {
              type: 'image',
              url: imageUrl.data.uploadImage,
              children: [{ text: '' }],
            }, { at: Editor.end(editor, []) });
            // 在图片后插入段落
            Transforms.insertNodes(editor, {
              type: 'paragraph',
              children: [{ text: '' }],
            }, { at: Editor.end(editor, []) });
          } else {
            const [selectedNodeEntry] = Editor.nodes(editor, {
              at: selection,
              match: n => SlateElement.isElement(n),
            });
    
            if (selectedNodeEntry) {
              const [, path] = selectedNodeEntry;
      
              // 在当前节点后插入一个新的 paragraph 节点
              Transforms.insertNodes(
                editor,
                {
                  type: 'image',
                  url: imageUrl.data.uploadImage,
                  children: [{ text: '' }],
                },
                { at: Path.next(path) } // 在当前节点之后插入
              );
              Transforms.move(editor);
            }
          }
        }
      });
    }
  }

  return (
    <div className="w-full h-auto min-h-96 flex flex-col">
      {/* <Picker data={data} onEmojiSelect={insertEmoji} /> */}
      <Slate editor={editor} initialValue={initialValue}>
        <header className="w-full h-auto pb-2 border-b border-gray-200 flex items-center gap-1">
          <MarkButton format="bold" icon={boldIcon} />
          <MarkButton format="italic" icon={italicIcon} />
          <MarkButton format="underline" icon={underlineIcon} />
          <button className="p-1 rounded-md hover:bg-gray-100">
            <img src={addEmojiIcon} alt="title" className="size-8" />
          </button>
          
          <BlockButton format="heading-one" icon={h1Icon} />
          <BlockButton format="heading-two" icon={h2Icon} />
          <BlockButton format="block-quote" icon={quoteIcon} />
          <BlockButton format="left" icon={alignLeftIcon} />
          <BlockButton format="center" icon={alignCenterIcon} />
          <BlockButton format="right" icon={alignRightIcon} />
          <button className="p-1 rounded-md hover:bg-gray-100"
            onClick={() => {
              Transforms.insertNodes(editor, {
                type: 'divider',
                children: [{ text: '' }],
              });
              // 插入一个新的空 paragraph 节点
              Transforms.insertNodes(editor, {
                type: 'paragraph',
                children: [{ text: '' }],
              });
              // 将光标移动到新插入的 paragraph 节点中
              Transforms.move(editor);
            }}
          >
            <img src={dividerIcon} alt="divider" className="size-8" />
          </button>
          <button className="p-1 rounded-md hover:bg-gray-100"
            onClick={() => {
              if (fileInputRef.current) {
                fileInputRef.current.click();
              }
            }}
          >
            <input
              id="imageInput"
              type="file"
              accept="image/*" // 只允许选择图片
              style={{ display: 'none' }} // 隐藏文件输入
              ref={fileInputRef}
              multiple={true}
              onChange={handleImageUpload}
            />
            <img src={addImageIcon} alt="addImage" className="size-8" />
          </button>
          
          <button className="p-1 rounded-md hover:bg-gray-100"
            onClick={() => {
              const pointStart = Editor.start(editor, []); // 文档的起点
              const pointEnd = Editor.end(editor, []); // 文档的终点
              // 删除整个范围的内容
              Transforms.delete(editor, {
                at: {
                  anchor: pointStart,
                  focus: pointEnd,
                },
              });        
            }}
          >
            <img src={clearIcon} alt="clear" className="size-8" />
          </button>

        </header>
        <Editable
          renderElement={renderElement}
          renderLeaf={renderLeaf}
          placeholder="请输入内容..."
          spellCheck
          autoFocus
          className={`rounded-md m-2 gap-4 border-2 flex flex-col ${dragOver ? 'border-dashed border-primary' : 'border-transparent'}`}
          onKeyDown={handleKeyDown}
          onDragOver={() => setDragOver(true)}
          onDragLeave={() => setDragOver(false)}
          onDrop={handleDrop}
          style={{
            minHeight: '384px', // 设置最小高度
            height: 'auto',
            width: 'calc(100% - 16px)',
            outline: 'none',
          }}
        />
      </Slate>
      <footer className="w-full h-auto pt-2 bg-white flex items-center justify-end gap-2 border-t border-gray-200">
        <button className="px-4 py-2 rounded-lg hover:bg-gray-100 hover:text-black/60
        " 
        disabled={isPublish||isSave}
        onClick={() => handleUploadPost(false)}>{isSave ? '保存中...' : '保存草稿'}</button>
        <button className="px-4 py-2 rounded-lg text-primary border border-primary hover:bg-primary/20 bg-primary/10 bg-white
        " 
        disabled={isPublish||isSave}
        onClick={() => handleUploadPost(true)}>{isPublish ? '提交中...' : '发布帖子'}</button>
      </footer>
    </div>
  )
}

const toggleBlock = (editor: Editor, format: string) => {
  const isActive = isBlockActive(
    editor,
    format,
    TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type'
  )

  // Transforms.unwrapNodes(editor, {
  //   match: n =>
  //     !Editor.isEditor(n) &&
  //     SlateElement.isElement(n) &&
  //     LIST_TYPES.includes(n.type) &&
  //     !TEXT_ALIGN_TYPES.includes(format),
  //   split: true,
  // })
  let newProperties: Partial<CustomElement>
  if (TEXT_ALIGN_TYPES.includes(format)) {
    newProperties = {
      align: isActive ? undefined : format as 'left' | 'center' | 'right',
    }
  } else {
    newProperties = {
      type: isActive ? 'paragraph' : format as 'heading-one' | 'heading-two' | 'block-quote',
    }
  }
  Transforms.setNodes<CustomElement>(editor, newProperties)
}

const toggleMark = (editor: Editor, format: string) => {
  const isActive = isMarkActive(editor, format)

  if (isActive) {
    Editor.removeMark(editor, format)
  } else {
    Editor.addMark(editor, format, true)
  }
}

const isBlockActive = (editor: Editor, format: string, blockType = 'type') => {
  const { selection } = editor
  if (!selection) return false

  const [match] = Array.from(
    Editor.nodes(editor, {
      at: Editor.unhangRange(editor, selection),
      match: n =>
        !Editor.isEditor(n) &&
        SlateElement.isElement(n) && (
          blockType === 'type' ? 
            n.type === format : 
            ((n.type === 'paragraph' || n.type === 'heading-one' || n.type === 'heading-two' )&& n.align === format)
        )
    })
  )
  return !!match
}

const isMarkActive = (editor: Editor, mark: string) => {
  const marks = Editor.marks(editor)
  switch (mark) {
    case 'bold':
      return marks ? marks.bold === true : false
    case 'italic':
      return marks ? marks.italic === true : false
    case 'underline':
      return marks ? marks.underline === true : false
  }
}

const Element = ({ attributes, children, element }: { attributes: any, children: any, element: any }) => {
  const selected = useSelected()
  const focused = useFocused()

  const style = { textAlign: element.align }
  switch (element.type) {
    case 'block-quote':
      return (
        <blockquote className="text-lg bg-gray-100 " style={style} {...attributes}>
          {children}
        </blockquote>
      )
    case 'heading-one':
      return (
        <h1 className="text-2xl font-bold" style={style} {...attributes}>
          {children}
        </h1>
      )
    case 'heading-two':
      return (
        <h2 className="text-xl font-bold" style={style} {...attributes}>
          {children}
        </h2>
      )
    case 'divider':
      return (
        <div {...attributes} contentEditable={false} className={`w-full h-auto flex flex-col justify-center items-center py-4 border-2 ${selected && focused ? 'border-primary' : 'border-transparent hover:border-primary/50'}`} 
        style={{
          lineHeight: 0,
        }}>
          <hr className="border-gray-200 w-1/2"/>
          {children}
        </div>
      );
      case 'image':
        return (
          <ImageComponent attributes={attributes} children={children} element={element} />
          // <div {...attributes} contentEditable={false} className={`flex flex-col items-center w-full h-auto`}>
          //   <img src={element.url} className={`w-auto h-auto max-w-full border-2 ${selected && focused ? 'border-primary' : 'border-transparent hover:border-primary/50'}`} />
          //   {children}
          // </div>
        );
    default:
      return (
        <p style={style} {...attributes}>
          {children}
        </p>
      )
  }
}

const Leaf = ({ attributes, children, leaf }: { attributes: any, children: any, leaf: any }) => {
  if (leaf.bold) {
    children = <strong>{children}</strong>
  }
  if (leaf.code) {
    children = <code>{children}</code>
  }
  if (leaf.italic) {
    children = <em>{children}</em>
  }
  if (leaf.underline) {
    children = <u>{children}</u>
  }
  return <span {...attributes}>{children}</span>
}

const BlockButton = ({ format, icon }: { format: string; icon: string }) => {
  const editor = useSlate()
  return (
    <button
      className={`p-1 rounded-md hover:bg-gray-200 ${isBlockActive(editor, format, TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type') ? 'bg-gray-200' : ''}`}
      onMouseDown={(event: React.MouseEvent) => {
        event.preventDefault()
        toggleBlock(editor, format)
      }}
    >
      <img src={icon} alt="title" className="size-8" />
    </button>
  )
}

const MarkButton = ({ format, icon }: { format: string; icon: string }) => {
  const editor = useSlate()
  return (
    <button
      className={`p-1 rounded-md hover:bg-gray-200 ${isMarkActive(editor, format) ? 'bg-gray-200' : ''}`}
      onMouseDown={(event: React.MouseEvent) => {
        event.preventDefault()
        toggleMark(editor, format)
      }}
    >
      <img src={icon} alt="title" className="size-8" />
    </button>
  )
}

export default PostEditor
import {BrowserRouter, Routes, Route} from 'react-router-dom';
import PageLayout from './pages/PageLayout';
import { useRecoilState } from 'recoil';
import NewLandingPage from './pages/NewLandingPage';
import { useAuth0 } from '@auth0/auth0-react';
import { graphql } from './gql';
import { useQuery } from '@apollo/client';
import { useEffect } from 'react';
import { userAtom } from './recoil/atoms/atoms';


const UserInfoQuery = graphql(`
  query UserInfoQuery{
    rayno {
      common {
        user {
          user_id
          user_name
          user_avatar_url
          user_signature
          user_last_login
        }
      }
    }
  }
`);



export const App = () => {
  const { isAuthenticated } = useAuth0();
  const [userInfo,setUserInfo] = useRecoilState(userAtom);
  const { data, loading, error } = useQuery(UserInfoQuery, {
    skip: !isAuthenticated,
  });
  
  useEffect(() => {
    if(data) {
      setUserInfo({
        userId: data.rayno.common.user.user_id,
        loggedIn: true,
        userName: data.rayno.common.user.user_name,
        role: 'user',
        avatarUrl: data.rayno.common.user.user_avatar_url,
      });
    }
  }, [data]);

  return (

    <BrowserRouter>
      <Routes>
        <Route path="/landing" element={<NewLandingPage />} />
        
        {/* 主页面布局 */}
        <Route path="/*" element={<PageLayout />} />
      </Routes>
    </BrowserRouter>
  );
};

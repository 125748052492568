import React from "react";
import { ApolloClient, InMemoryCache } from "@apollo/client";
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs';
import { ApolloProvider } from "@apollo/client/react";
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';
import { split } from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';
import { useAuth0 } from '@auth0/auth0-react';
import { setContext } from '@apollo/client/link/context';
// import { subscriptionsClient } from 'subscriptions-transport-ws';
// import dotenv from 'dotenv';
// dotenv.config();

import { loadEnv } from './utils/util';

loadEnv();


// ApolloClient 配置
// const url: string = 'https://api.getrayno.com/graphql';
const url: string = process.env.REACT_APP_API_URL!;
const websocketUrl: string = process.env.WEBSOCKET_URL!;

// 自定义 Apollo Client
const ApolloClientProvider = ({ children }: { children: React.ReactNode }) => {
  const {  getAccessTokenSilently } = useAuth0();

  // // 设置 Authorization 头的上下文
  const authLink = setContext(async (_, { headers }) => {
    try {
      const token = await getAccessTokenSilently(); // 获取 accessToken
      return {
        headers: {
          ...headers,
          Authorization: `Bearer ${token}`, // 将 token 添加到 Authorization 头
        },
      };
    } catch (error) {
      console.error("Failed to get access token:", error);
      return { headers };
    }
  });

  // 文件上传 link
  const uploadLink = createUploadLink({
    uri: url,
  });

  // WebSocket link
  const wsLink = new GraphQLWsLink(
    createClient({
      url: websocketUrl, // 后端 WebSocket 地址
      connectionParams: async () => {
        try {
          const token = await getAccessTokenSilently();
          return {
            Authorization: `Bearer ${token}`,
          };
        } catch (error) {
          console.error("Failed to get access token for WebSocket:", error);
          return {};
        }
      },
      shouldRetry: () => true,
      on: {
        connected: () => console.log("WebSocket connected"),
        error: (err) => console.error("WebSocket error:", err),
        closed: () => console.log("WebSocket closed"),
      }
    })
  );

  // 根据操作类型选择使用 HTTP Link 还是 WebSocket Link
  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === "OperationDefinition" &&
        definition.operation === "subscription"
      );
    },
    wsLink, // 使用 WebSocket Link 处理订阅
    authLink.concat(uploadLink) // 使用 HTTP Link 处理查询和变更
  );


  // Apollo Client 配置
  const client = new ApolloClient({
    link: splitLink,
    cache: new InMemoryCache(),
  });

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default ApolloClientProvider;
import React, { useState,useEffect } from 'react';
import { graphql } from '../../gql';
import { useQuery, useMutation} from '@apollo/client';
import Pagination from '../common/Pagination';

interface BlockedUserItemProps {
    userId: string | null;
    userName: string;
    userAvatarUrl: string;
    blockedState: boolean;
}

const changeBlockedStateMutation = graphql(`
    mutation changeBlockedState($blocked_user_id: String!) {
        changeBlockedState(blocked_user_id: $blocked_user_id)
    }
`);

const blockedUserQuery = graphql(`
    query Rayno($page: Int!) {
        rayno {
            user_home_page {
                blocked_list_model(page: $page) {
                    blocked_user_count
                    blocked_users {
                        user_id
                        user_name
                        user_avatar_url
                    }
                }
            }
        }
    }
`);

export const BlockedUserItem = (props: BlockedUserItemProps) => {

    const [blockedState, setBlockedState] = useState(props.blockedState);
    const [ changeBlockedState, { loading, error } ] = useMutation(changeBlockedStateMutation);

    return (
        <div className="py-2 px-4 border-b flex items-center">
            <img src={props.userAvatarUrl} alt={props.userName} className="w-10 h-10 rounded-full" />
            <span className="text-lg">{props.userName}</span>
            <button className="bg-primary text-white px-2 py-1 rounded-md" onClick={() => {
                changeBlockedState({variables: {blocked_user_id: props.userId!}});
                setBlockedState(!blockedState);
            }}>
                {
                    loading ? "Loading..." :
                    error ? "Error" :
                    blockedState ? "移出黑名单" : "拉黑"
                }
            </button>
        </div>
    );
}


const BlackListBlock: React.FC = () => {

    const [pageNumber, setPageNumber] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [items, setItems] = useState<BlockedUserItemProps[]>([]);

    const { data, loading, error, refetch } = useQuery(blockedUserQuery, {
        variables: {
            page: currentPage
        }
    });

    if(loading) {
        return <div>Loading...</div>;
    } else if(error) {
        return <div>Error: {error.message}</div>;
    }
    
    setPageNumber(Math.ceil(data!.rayno.user_home_page.blocked_list_model.blocked_user_count/20));
    const blockedListModel = data!.rayno.user_home_page.blocked_list_model;
    setItems(blockedListModel.blocked_users.map((user: { user_id: string; user_name: string; user_avatar_url: string; }, index: number) => ({
        userId: user.user_id,
        userName: user.user_name,
        userAvatarUrl: user.user_avatar_url,
        blockedState: true
    })));
    if(blockedListModel.blocked_user_count === 0) {
        return (
            <div className="flex flex-col items-center w-3/4 h-full pt-8">
                <span className='text-gray-400'>你还没有拉黑任何人</span>
            </div>
        );
    }

    return (
    <div className="flex flex-col items-center justify-center w-3/4 h-full">
        {items.filter(item => item.userId !== null).map((item, index) => (
            <BlockedUserItem key={index} userId={item.userId} userName={item.userName} userAvatarUrl={item.userAvatarUrl} blockedState={item.blockedState} />
        ))}
        <Pagination currentPage={currentPage} totalPages={pageNumber} switchToPage= {
            (page) => {
                setCurrentPage(page);
                refetch({
                    page: page
                });
            }
        } />
    </div>
    );
};

export default BlackListBlock;
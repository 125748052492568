import React, { useState, useEffect } from 'react';
import {Routes, Route} from 'react-router-dom';
import NewPost from '../Post';
import { ColorRing } from 'react-loader-spinner';

const PrimaryBlock: React.FC = () => {

  return (
    <div className="flex flex-col items-center justify-center w-3/4 h-full">
      <ColorRing
        height="120"
        width="120"
        colors={["#FF8A00"]}
        radius="2"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
        ariaLabel="rings-loading"
      />
    </div>
  );
};

export default PrimaryBlock;

import React from 'react';
import NavigationBar from '../components/NavigationBar';

const IntroPage: React.FC = () => {
  return (
    <div className="flex h-full w-full">
      <div className="flex-grow min-w-72 h-full"/>
      <div className="flex-grow bg-gray-100 w-90">
      </div>
      <div className="flex-grow min-w-72 h-full"/>
    </div>
  );
};

export default IntroPage;

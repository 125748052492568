import React, {useEffect, useState} from 'react';
import logo from '../../../public/assets/logo.png';
import UnfocusHomeIcon from '../../../public/assets/HomeFull1.svg';
import FocusHomeIcon from '../../../public/assets/HomeFull0.svg';
import UnfocusMsgIcon from '../../../public/assets/MsgFull0.svg';
import FocusMsgIcon from '../../../public/assets/MsgFull1.svg';
import FocusProfileIcon from '../../../public/assets/profileFull1.svg';
import UnfocusProfileIcon from '../../../public/assets/profileFull0.svg';
import FocusEditIcon from '../../../public/assets/EditFull1.svg';
import UnfocusEditIcon from '../../../public/assets/EditFull0.svg';
import FocusNotificationIcon from '../../../public/assets/NotificationFull1.svg';
import UnfocusNotificationIcon from '../../../public/assets/NotificationFull0.svg';
import FocusIntroIcon from '../../../public/assets/IntroFull1.svg';
import UnfocusIntroIcon from '../../../public/assets/IntroFull0.svg';
import FocusMoreIcon from '../../../public/assets/MoreFull1.svg';
import UnfocusMoreIcon from '../../../public/assets/MoreFull0.svg';
import moreIcon from '../../../public/assets/more.svg';
import {Link, useLocation} from 'react-router-dom';
import { graphql } from '../../gql';
import { useQuery } from '@apollo/client';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { userAtom } from '../../recoil/atoms/atoms';
import { Oval } from 'react-loader-spinner';

const SideBarItem: React.FC<{
  tag: string;
  unFocusedIcon: string;
  focusedIcon: string;
  text: string;
  link: string;
}> = ({tag, unFocusedIcon, focusedIcon, text, link}) => {
  const [userState, setUserState] = useRecoilState(userAtom);
  const currentPath = useLocation();
  const [isFocused, setIsFocused] = useState(false);
  const { isAuthenticated, loginWithRedirect } = useAuth0();
  
  const navigate = useNavigate();
  useEffect(() => {
    if (currentPath.pathname.split('/')[1] === tag) {
      setIsFocused(true);
    } else {
      setIsFocused(false);
    }
  }, [currentPath.pathname]);

  return (
    <div className="w-full">
      {/* <Link to={link}> */}
        <button onClick={() => {
          if (isAuthenticated || ['/home', '/intro', '/more'].includes(link)) {
            if (link === '/user') {
              navigate('/user/'+userState.userName);
            } else {
              navigate(link);
            }
          } else {
            loginWithRedirect();
          }
        }} className="flex w-full justify-start items-center pl-6 gap-4 hover:bg-gray-100 rounded-full py-2">
          <img
            src={isFocused ? focusedIcon : unFocusedIcon}
            alt={text}
            className="size-8"
          />
          <span
            className={`text-xl ${isFocused ? 'font-bold' : 'font-normal'}`}
          >
            {text}
          </span>
        </button>
      {/* </Link> */}
    </div>
  );
};

const LeftSideBarQuery = graphql(`
  query LeftSideBarQuery{
    rayno {
      left_side_bar {
        home_button
        notification_button
        msg_button
        user_home_button
        create_post_button
        intro_button
        more_button
        login_button
        register_button
      }
    }
  }
`);

// const UserInfoQuery = graphql(`
//   query userInfoQuery {
//     rayno{
//       common {
//         user {
//           user_id
//           user_name
//           user_avatar_url
//           user_signature
//           user_last_login
//         }
//       }
//     }
//   }
// `);

const LeftSideBar: React.FC = () => {
  const { logout, loginWithRedirect, isAuthenticated, isLoading } = useAuth0();
  const [userState, setUserState] = useRecoilState(userAtom);
  const navigate = useNavigate();

  // const [loginWindowStatus, setLoginWindowStatus] = useRecoilState(loginWindowAtom);
  // const [registerWindowStatus, setRegisterWindowStatus] = useRecoilState(registerWindowAtom);

  const {loading, error, data: rawData} = useQuery(LeftSideBarQuery);
  
  if (isLoading) return (
    <div className="fixed flex flex-col items-center justify-center h-full w-72 border-r pt-4 pb-8 bg-white">
      {/* <span className="text-xl font-bold">Loading...</span> */}
      <div className="flex items-center justify-center"><Oval
        color="#ff8a00"
        secondaryColor="#FF8A0080"
        height={40}
        width={40}
      /></div>
    </div>
  )
  else if (error || !rawData) return (
    <div className="fixed flex flex-col items-center justify-center h-full w-72 border-r pt-4 pb-8 bg-white">
      <span className="text-xl font-bold">Error: {error?.message}</span>
    </div>
  );
  const data = rawData.rayno.left_side_bar;

  return (
    <div className="fixed flex flex-col items-center h-full w-72 border-r pt-4 pb-8 bg-white z-50">
      <div>
        <img src={logo} alt="logo" className="size-16" />
      </div>
      <div className="flex flex-col w-full px-8 gap-2 mt-4">
        <SideBarItem
          unFocusedIcon={UnfocusHomeIcon}
          focusedIcon={FocusHomeIcon}
          tag="home"
          text={data.home_button}
          link="/home"
        />
        <SideBarItem
          unFocusedIcon={UnfocusNotificationIcon}
          focusedIcon={FocusNotificationIcon}
          tag="notification"
          text={data.notification_button}
          link="/notification"
        />
        <SideBarItem
          unFocusedIcon={UnfocusMsgIcon}
          focusedIcon={FocusMsgIcon}
          tag="msg"
          text={data.msg_button}
          link="/msg"
        />
        <SideBarItem
          unFocusedIcon={UnfocusProfileIcon}
          focusedIcon={FocusProfileIcon}
          tag="user"
          text={data.user_home_button}
          link="/user"
        />
        <SideBarItem
          unFocusedIcon={UnfocusEditIcon}
          focusedIcon={FocusEditIcon}
          tag="edit"
          text={data.create_post_button}
          link="/edit"
        />
        <SideBarItem
          unFocusedIcon={UnfocusIntroIcon}
          focusedIcon={FocusIntroIcon}
          tag="intro"
          text={data.intro_button}
          link="/intro"
        />
        <SideBarItem
          unFocusedIcon={UnfocusMoreIcon}
          focusedIcon={FocusMoreIcon}
          tag="more"
          text={data.more_button}
          link="/more"
        />
      </div>

      <div className="flex-grow" />

      <div>
        {isAuthenticated ? (
          <div className="flex items-center gap-2 w-full px-4">
            <img src={userState.avatarUrl || ''} onClick={() => {navigate('/user/'+userState.userName)}} className="size-12 rounded-full cursor-pointer" />
            <span className="text-lg">{userState.userName || 'unknown'}</span>
            <div className="flex-grow"/>
            <button onClick={() => {logout()}} className="hover:bg-gray-100 rounded-full px-2 py-1 min-w-12">
              {/* <img src={moreIcon} className="size-8" /> */}
              <span>退出</span>
            </button>
          </div>
        ) : (
          <div className="flex gap-2">
            <button
              onClick={() => {
                // setLoginWindowStatus(true);
                loginWithRedirect();
              }}
              className="w-20 h-10 bg-primary text-white hover:bg-primary/80 font-bold rounded-full"
            >
              {data.login_button}
            </button>
            <button
              onClick={() => 
                // setRegisterWindowStatus(true)
                logout()
                // loginWithRedirect({
                //   authorizationParams: {
                //     screen_hint: "signup"
                //   }
                // })
              }
              className="w-20 h-10 text-primary font-bold hover:bg-gray-200 rounded-full"
            >
              {data.register_button}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default LeftSideBar;
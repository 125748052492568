import {createRoot} from 'react-dom/client';
import {App} from './App';
// import { WebSocketLink } from '@apollo/client/link/ws';
const container = document.getElementById('app');
const root = createRoot(container as HTMLElement);
import ApolloClientProvider from './ApolloClientProvider';
import { Auth0Provider } from '@auth0/auth0-react';
import { RecoilRoot } from 'recoil';

import { loadErrorMessages, loadDevMessages } from "@apollo/client/dev";

if(process.env.NODE_ENV === 'development') {
  loadDevMessages();
  loadErrorMessages();
}

// const wsLink = new WebSocketLink({
//   uri: url.replace('http', 'ws'),
//   options: {
//     reconnect: true,
//   },
// });

// const splitLink = split(
// )

root.render(
  <Auth0Provider
    domain="dev-urouswhgv1y22m0u.us.auth0.com"
    clientId="XlprJBev9dFpOlOeP0NvZ2eSEzt7Agxq"
    authorizationParams={{
      redirect_uri: window.location.origin,
      audience: "https://dev-urouswhgv1y22m0u.us.auth0.com/api/v2/",
      scope: "read:current_user openid profile email update:current_user_metadata offline_access"
    }}
  >
    <ApolloClientProvider>
      <RecoilRoot>
        <App />
      </RecoilRoot>
    </ApolloClientProvider>
  </Auth0Provider>
);

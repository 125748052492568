import React, {useEffect} from 'react';
import LeftSideBar from '../components/common/LeftSideBar';
import {Routes, Route, Navigate} from 'react-router-dom';
import MainPage from './MainPage';
import UserHomePage from './UserHomePage';
import MsgPage from './MsgPage';
import {useRecoilState} from 'recoil';
import {userAtom, loginWindowAtom, registerWindowAtom, commentWindowAtom} from '../recoil/atoms/atoms';
import LoginWindow from '../components/LoginWindow';
import RegisterWindow from '../components/RegisterWindow';
import NotificationPage from './NotificationPage';
import IntroPage from './IntroPage';
import EditorPage from './EditorPage';
import {useCookies} from 'react-cookie';
// import {getUserInfo} from '../utils/util';
import CommentWindow from '../components/CommentWindow';


const PageLayout: React.FC = () => {
  const [loginWindowStatus, setLoginWindowStatus] = useRecoilState(loginWindowAtom);
  const [registerWindowStatus, setRegisterWindowStatus] = useRecoilState(registerWindowAtom);
  const [commentWindowStatus, setCommentWindowStatus] = useRecoilState(commentWindowAtom);
  // const [loginStatus, setLoginStatus] = useRecoilState(userAtom);
  // const [cookies] = useCookies(['refreshToken']);

  // useEffect(() => {
  //   const fetchUserInfo = async () => {
  //     const refreshToken = cookies.refreshToken;
  //     if(refreshToken) {
  //       const userInfo = await getUserInfo(refreshToken);
  //       setLoginStatus({
  //         userId: userInfo.userId,
  //         loggedIn: true,
  //         userName: userInfo.userName,
  //         role: userInfo.role,
  //         avatarUrl: userInfo.avatarUrl,
  //       });
  //     } else {
  //       setLoginStatus({
  //         userId: '',
  //         loggedIn: false,
  //         userName: '',
  //         role: '',
  //         avatarUrl: '',
  //       });
  //     }
  //   }
  //   fetchUserInfo();
  // }, []);

  return (
    <div className="flex h-screen w-[calc(100vw-16px)]">
      {(loginWindowStatus || registerWindowStatus) && (
        <div className="fixed inset-0 w-screen h-screen z-[97] flex justify-center items-center">
          {loginWindowStatus ? <LoginWindow /> : <RegisterWindow />}
          <div className="w-full fixed inset-0 bg-gray-500 bg-opacity-50 z-[98]" />
        </div>
      )}
      {
        commentWindowStatus && (
          <div className="fixed inset-0 w-screen h-screen z-[97] flex justify-center items-center">
            <CommentWindow postId="asd" userId="asd" commentsCount={10} />
            <div className="w-full fixed inset-0 bg-gray-500 bg-opacity-50 z-[98]" />
          </div>
        )
      }
      

      <LeftSideBar/>

      <div className="w-full h-full">
        <Routes>
          <Route path="/home/*" element={<MainPage />} />
          <Route path="/user/:username" element={<UserHomePage/>} />
          <Route path="/msg/*" element={<MsgPage />} />
          <Route path="/notification/*" element={<NotificationPage />} />
          <Route path="/intro/*" element={<IntroPage />} />
          <Route path="/edit/*" element={<EditorPage />} />
          <Route path="/*" element={<Navigate to="/home" />} />
        </Routes>
      </div>
    </div>
  );
};

export default PageLayout;

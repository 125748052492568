import React from 'react';

const SettingsBlock: React.FC = () => {
  return (
    <div className='w-full h-full flex flex-col items-start justify-start gap-4'>
        <span className='text-md font-bold'>隐私设置</span>
        <span className='text-md font-bold'>个人设置</span>
    </div>
  );
};

export default SettingsBlock;
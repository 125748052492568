import React, {useEffect, useState, useMemo} from 'react';
import SessionItem from '../components/MsgPage/SessionItem';
import { SessionItemProps } from '../components/MsgPage/SessionItem';
import { MsgItemProps } from '../components/MsgPage/MsgItem';
import { graphql } from '../gql';
import { useLazyQuery, useSubscription } from '@apollo/client';
import ChatPanel from '../components/MsgPage/ChatPanel';
import { Oval } from 'react-loader-spinner';
import { MSG_SUBSCRIPTION } from '../subscription/MsgSub';
import { useRecoilState } from 'recoil';
import { userAtom } from '../recoil/atoms/atoms'
import { uuidv7 } from 'uuidv7';
import { ChatPanelProps } from '../components/MsgPage/ChatPanel';

const SessionListQuery = graphql(`
  query sessionListQuery {
    rayno {
      msg_page {
        sessions {
          id
          unread_count
          last_msg {
            content
            msg_type
            created_at
          }
          user1 {
            user_id
            user_name
            user_avatar_url
          }
          user2 {
            user_id
            user_name
            user_avatar_url
          }
        }
      }
    }
  }
`)

const MsgPage: React.FC = () => {

  const [ userInfo, setUserInfo] = useRecoilState(userAtom);
  const [focusSessionId, setFocusSessionId] = useState<string>('');
  const [sessionList, setSessionList] = useState<SessionItemProps[]>([]);
  const [getSessionList, {loading: sessionListLoading, error: sessionListError}] = useLazyQuery(SessionListQuery, {
    onCompleted: (data) => {
      console.log(data);
      setSessionList(data.rayno.msg_page.sessions.map(item => ({
        id: item.id,
        last_msg_at: new Date(item.last_msg.created_at),
        unread_count: item.unread_count,
        last_message_content: item.last_msg.msg_type === 'text' ? item.last_msg.content : '[图片]',
        last_message_type: item.last_msg.msg_type,
        participant: item.user1.user_id === "019453d9-0411-760c-927a-a7d5312083d2" ? item.user2 : item.user1,
        hasMoreMsg: true,
        msgList: [],
        cursor: '',
        reachTop: false
      })));
      setFocusSessionId(data.rayno.msg_page.sessions[0].id);
    }
  });

  const currentChatPanelProps: ChatPanelProps = useMemo(() => {
    return {
      sessionId: focusSessionId,
      participant: sessionList.find(item => item.id === focusSessionId)?.participant || { user_id: '', user_name: '', user_avatar_url: '' },
      msgList: sessionList.find(item => item.id === focusSessionId)?.msgList || [],
      setMsgList: (msgs) => setSessionList(sessionList.map(item => item.id === focusSessionId ? {...item, msgList: msgs} : item)),
      reachTop: sessionList.find(item => item.id === focusSessionId)?.reachTop || false,
      setReachTop: (reachTop) => setSessionList(sessionList.map(item => item.id === focusSessionId ? {...item, reachTop: reachTop} : item))
    };
  }, [focusSessionId, sessionList]);

  const {data: msgData, loading: msgLoading, error: msgError} = useSubscription(MSG_SUBSCRIPTION, {
    variables: {
      userId: "019453d9-0411-760c-927a-a7d5312083d2",
    },
    onData: (data) => {
      const { content, msgType, senderId, createdAt, sessionId } = data.data.data?.newMsg!;
      if (sessionList.find(item => item.id === sessionId)) {
        // 如果消息列表中存在该会话，则将新消息添加到该会话的消息列表中
        const msgList = sessionList.find(item => item.id === sessionId)?.msgList;
        if (msgList && msgList.length > 0) {
          msgList.push({
            id: uuidv7(),
            content,
            sender_id: senderId,
            created_at: new Date(createdAt),
            previous_msg_time: msgList.length === 0 ? null : msgList[msgList.length - 1].created_at,
            msg_type: msgType,
            layout: senderId === 'system' ? 'center' : 'left',
            sender_avatar_url: '',
            status: 'sent'
          });
          setSessionList(sessionList.map(item => item.id === sessionId ? {...item, msgList: msgList, last_msg_at: new Date(createdAt), unread_count: item.unread_count + 1, last_message_content: msgType === 'text' ? content : '[图片]'} : item));
        }
        else {
          setSessionList(sessionList.map(item => item.id === sessionId ? {...item, last_msg_at: new Date(createdAt), unread_count: item.unread_count + 1, last_message_content: msgType === 'text' ? content : '[图片]'} : item));
        }
      } else {
        setSessionList(sessionList.concat({
          id: sessionId,
          last_msg_at: new Date(createdAt),
          unread_count: 1,
          last_message_content: msgType === 'text' ? content : '[图片]',
          last_message_type: msgType,
          participant: {
            user_id: senderId,
            user_name: '',
            user_avatar_url: '',
          },
          hasMoreMsg: true,
          msgList: [], // 当收到消息创建新的session的时候 要怎么初始化msgList TODO
          cursor: new Date(createdAt).toISOString(),
          reachTop: false
        }));
      }
    }
  });
  
  useEffect(() => {
    getSessionList();
  }, []);

  if (sessionListLoading) {
    return (
      <div className='w-full h-full flex items-center justify-center'>
        <Oval color="#ff8a00" secondaryColor="#FF8A0080" height={48} width={48} />
      </div>
    );
  }
  else if (sessionListError) {
    return (
      <div className='w-full h-full flex items-center justify-center'>
        <div className='text-xl font-bold'>Error: {sessionListError.message}</div>
      </div>
    );
  }

  console.log(sessionList.map(item => item.participant.user_avatar_url));
  console.log(sessionList.map(item => item.participant.user_id));
  console.log(userInfo.userId);

  return (
    <div className="flex h-full w-full ">
      <div id='left-side-bar' className='min-w-72 h-full'/>
      <div className="flex flex-col min-w-80 border-r">
        <div className="w-full h-16 flex items-center justify-center">
          <span className="text-xl font-bold">全部消息</span>
        </div>
        <div className="w-full flex-grow">
          {sessionList.map(item => (
            <div
              onClick={() => {
                setFocusSessionId(item.id);
                setSessionList(sessionList.map(otherItem => otherItem.id === item.id ? {...item, unread_count: 0} : otherItem));
              }}
              className={`hover:bg-gray-100 ${focusSessionId === item.id ? 'bg-gray-100' : ''}`}
              key={item.id}
            >
              <SessionItem {...item} />
            </div>
          ))}
        </div>
      </div>

      <div className="w-full flex flex-col h-full border-y flex-grow">
        <ChatPanel 
          {...currentChatPanelProps}
         />
      </div>
    </div>
  );
};

export default MsgPage;